<template>
  <div class="order-page--fulfill">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect
          v-if="data"
          class="mb-5"
          :title="data.name"
          :routeWithId="{
            name: 'website.orders.detail',
            params: {
              id: data.id,
            },
          }"
        />
        <div class="page-header">
          <h2 class="page-title">{{ 'Refund items' }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col v-if="data" cols="12" sm="8" class="d-flex flex-column">
        <div v-if="refundItems.filter(item => item.fulfilled).length" class="elevation-form pa-3">
          <div class="d-flex align-center mb-6 mr-2">
            <v-avatar size="32" class="mr-2">
              <img :src="'@/assets/images/checked.png'" alt="" />
            </v-avatar>
            <h4>Fulfilled by LatteHub ({{ refundItems.filter(item => item.fulfilled).length }})</h4>
          </div>
          <div>
            <v-simple-table class="table-fulfillment">
              <template v-slot:default>
                <tbody>
                  <tr
                    class="form-component"
                    v-for="(item, k) in refundItems.filter(item => item.fulfilled)"
                    :key="item.item_id"
                  >
                    <td>
                      <div class="image-item">
                        <img
                          v-if="!data.itemsInfo.find(i => item.item_id === i.item_id).image"
                          :src="'@/assets/images/no-image.png'"
                          alt=""
                        />
                        <img
                          style="width: 100%"
                          v-else
                          :src="imageHelpers.url(data.itemsInfo.find(i => item.item_id === i.item_id).image)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="ml-3 py-3">
                        <router-link
                          v-if="item.sku !== 'tip'"
                          :to="{ name: 'website.products.update', params: { id: item.product_id } }"
                        >
                          {{ item._title }}
                        </router-link>
                        <span v-else>{{ item._title }}</span>
                        <div>{{ item._variant_variantTitle }}</div>
                        <div v-for="(personalize, index) in item.personalizeText" :key="index">
                          {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                        </div>
                        <div>SKU:{{ item._sku }}</div>
                        <div>Price: {{ currency.priceFormat(item.price) }}</div>
                      </div>
                    </td>
                    <td>
                      <div class="ml-3 py-3">
                        <v-text-field
                          hide-details
                          width="50px"
                          style="width: 100px"
                          :max="item.qty"
                          v-model.number="item.refunded_qty"
                          type="number"
                          @blur="checkQYT(k, item.qty)"
                          @change="changeQty(item)"
                          :suffix="`of ${item.qty}`"
                        ></v-text-field>
                      </div>
                    </td>
                    <td>
                      {{
                        currency.priceFormat(
                          parseFloat(parseInt(item.refunded_qty) * parseFloat(item.price)).toFixed(2),
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <!-- ######################################################              UNFULFILL -->
        <div v-if="refundItems.filter(item => !item.fulfilled).length" class="elevation-form pa-3">
          <div class="d-flex align-center mb-6 mr-2">
            <v-avatar size="32" class="mr-2">
              <img :src="imageFake.unfulfilled" alt="" />
            </v-avatar>
            <h4>Unfulfilled ({{ refundItems.filter(item => !item.fulfilled).length }})</h4>
          </div>
          <div>
            <v-simple-table class="table-fulfillment">
              <template v-slot:default>
                <tbody>
                  <tr
                    class="form-component"
                    v-for="(item, k) in refundItems.filter(item => !item.fulfilled)"
                    :key="item.item_id"
                  >
                    <td>
                      <div class="image-item">
                        <img
                          v-if="!data.itemsInfo.find(i => item.item_id === i.item_id).image"
                          :src="'@/assets/images/no-image.png'"
                          alt=""
                        />
                        <img
                          v-else-if="item._vendor === 'personalbridge'"
                          style="width: 100%"
                          :src="
                            imageHelpers.url(
                              data.itemsInfo
                                .find(i => item.item_id === i.item_id)
                                .attributes.find(i => i.key === 'print_files').value[0].url,
                            )
                          "
                        />
                        <img
                          v-else
                          style="width: 100%"
                          :src="imageHelpers.url(data.itemsInfo.find(i => item.item_id === i.item_id).image)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="ml-3 py-3">
                        <router-link
                          v-if="item.sku !== 'tip'"
                          :to="{ name: 'website.products.update', params: { id: item.product_id } }"
                        >
                          {{ item._title }}
                        </router-link>
                        <span v-else>{{ item._title }}</span>

                        <div>{{ item._variant_variantTitle }}</div>
                        <div v-if="personalizeText(item)">
                          <div v-for="(personalize, index) in personalizeText(item)" :key="index">
                            {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                          </div>
                        </div>
                        <div>SKU:{{ item._sku }}</div>
                        <div>Price: {{ currency.priceFormat(item.price) }}</div>
                      </div>
                    </td>
                    <td>
                      <div class="ml-3">
                        <v-text-field
                          hide-details
                          width="50px"
                          style="width: 100px"
                          :max="item.qty"
                          v-model.number="item.refunded_qty"
                          type="number"
                          @blur="checkQYT(k, item.qty)"
                          @change="changeQty(item)"
                          :suffix="`of ${item.qty}`"
                        ></v-text-field>
                      </div>
                    </td>
                    <td>{{ currency.priceFormat(calculateItemsPrice(item)) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <div v-if="parseFloat(data.paidInfo.shipping_total) > 0" class="elevation-form pa-3 mt-3">
          <div class="d-flex align-center mr-2">
            <h3>{{ 'Refund shipping' }}</h3>
          </div>
          <div class="my-3">
            Shipping rate:
            <strong
              >{{ data.paidInfo.shipping_title }} ({{ currency.priceFormat(data.paidInfo.shipping_total) }})</strong
            >
          </div>
          <div>
            <label for="">Refund amount</label>
            <v-text-field type="number" hide_details @blur="checkShipping" v-model="shippingAmount"></v-text-field>
            <span v-if="totalShippingRefunded > 0"
              >{{ currency.priceFormat(totalShippingRefunded) }} has already been refunded</span
            >
          </div>
        </div>
        <div v-if="data.paidInfo.tax && parseFloat(data.paidInfo.tax) > 0" class="elevation-form pa-3 mt-3">
          <div class="d-flex align-center mr-2">
            <h3>{{ 'Refund tax' }}</h3>
          </div>
          <div class="my-3">
            Tax amount: <strong>({{ currency.priceFormat(parseFloat(data.paidInfo.tax).toFixed(2)) }})</strong>
          </div>
          <div>
            <label for="">Refund amount</label>
            <v-text-field type="number" hide_details @blur="checkTax" v-model="taxAmount"></v-text-field>
          </div>
        </div>
        <div class="elevation-form pa-3 mt-3">
          <div class="d-flex align-center mr-2">
            <h3>{{ 'Reason for refund' }}</h3>
          </div>
          <div class="mt-3">
            <v-text-field hide_details v-model="reasonRefund"></v-text-field>
            <span>Your customer won't see this.</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <div class="">
            <h4>Summary</h4>
            <div class="py-3" v-if="grandTotal > 0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <div>Items subtotal</div>
                        <div>{{ countItemsRefunded }} items</div>
                      </td>
                      <td class="text-right">{{ currency.priceFormat(subTotal) }}</td>
                    </tr>
                    <tr>
                      <td>
                        <div>Shipping</div>
                      </td>
                      <td class="text-right">{{ currency.priceFormat(shippingAmount) }}</td>
                    </tr>
                    <tr>
                      <td class="py-2" colspan="2">
                        <div>Tax</div>
                        <ul>
                          <li class="ml-3">
                            <div class="d-flex items-center justify-space-between">
                              <div>Items:</div>
                              <div>{{ currency.priceFormat(parseFloat(taxAmountItems).toFixed(2)) }}</div>
                            </div>
                          </li>
                          <li class="ml-3">
                            <div class="d-flex items-center justify-space-between">
                              <div>Amount:</div>
                              <div>{{ currency.priceFormat((taxAmount - taxAmountItems).toFixed(2)) }}</div>
                            </div>
                          </li>
                        </ul>
                      </td>
                      <!-- <td class="text-right">{{ currency.priceFormat(taxAmount) }}</td>
                      <td colspan="2">$50</td> -->
                    </tr>
                    <tr>
                      <td>
                        <div>Refund amount</div>
                      </td>
                      <td class="text-right">{{ currency.priceFormat(grandTotal) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="py-3" v-else>No items selected.</div>
          </div>
        </div>
        <div class="elevation-form pa-3">
          <div class="">
            <h4>REFUND AMOUNT</h4>
            <div>
              <label for="Paypal Express"></label>
              <v-text-field hide_details @blur="checkRefundedAmout" v-model="refundedAmout"></v-text-field>
              <span>
                {{ currency.priceFormat(parseFloat(data.paidInfo.grand_total - totalRefunded).toFixed(2)) }} available
                for refund
              </span>
            </div>
          </div>
        </div>
        <div class="elevation-form pa-3">
          <div class="d-flex justify-space-between">
            <v-btn
              color="primary"
              class="w-100"
              @click="onRefundedItems"
              :loading="isLoadingAction"
              :disabled="isLoadingAction || refundedAmout == 0"
            >
              Refund <span class="ml-2" v-if="refundedAmout > 0">{{ currency.priceFormat(refundedAmout) }}</span>
            </v-btn>
          </div>
          <div class="mt-3">
            <span>Removed items can't be fulfilled.</span>
          </div>
          <div>
            <v-checkbox v-model="sendEmail" label="Send a notification to the customer"></v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
    <delete-action :content="'product ' + name" :title="'Delete ' + name" />
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { orderApi } from '@/apis/order';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import STORAGE_NAME from '@/const/storage';
import datetime from '@/helpers/datetime';
import currency from '@/helpers/currency';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {},
  data() {
    return {
      sendEmail: true,
      timeline: [],
      datetime,
      events: [],
      input: null,
      nonce: 0,
      imageFake,
      imagesSelect: [],
      isVariantDefault: false,
      showDialogDelete: false,
      name: '',
      idItem: null,
      isLoading: false,
      loading: false,
      page: {},
      showHeader: false,
      searchString: 'null',
      listVariants: [],
      // Product options
      options: [], // need
      imageHelpers,
      data: null,
      totalPrice: 0,
      refundedItems: [],
      refundItems: [],
      shippingAmount: 0,
      taxAmount: 0,
      refundedAmout: 0,
      reasonRefund: '',
      itemsFulfilled: [],
      itemsRefundFulfilled: [],
      itemsRefundUnfulfilled: [],
      refundedData: null,
      isLoadingAction: false,
      resason: '',
      taxAmountItems: 0,
      currency,
    };
  },
  async created() {
    this.getData();
  },
  methods: {
    changeQty(item) {
      let itemData = this.data.itemsInfo.find(i => i.item_id === item.item_id);
    },
    checkRefundedAmout() {
      if (
        parseFloat(this.refundedAmout) > parseFloat(this.data.paidInfo.grand_total - this.totalRefunded) ||
        parseFloat(this.refundedAmout) < 0
      ) {
        this.refundedAmout = parseFloat(this.data.paidInfo.grand_total - this.totalRefunded).toFixed(2);
      }
      if (!this.refundedAmout) {
        this.refundedAmout = 0;
      }
    },
    checkTax() {
      if (parseFloat(this.taxAmount) > parseFloat(this.data.paidInfo.tax) || parseFloat(this.taxAmount) < 0) {
        this.taxAmount = this.data.paidInfo.tax;
      }
      if (!this.taxAmount) {
        this.taxAmount = 0;
      }
      this.refundedAmout = this.grandTotal;
    },
    checkShipping() {
      if (
        parseFloat(this.shippingAmount) > parseFloat(this.availableRefundShippings) ||
        parseFloat(this.shippingAmount) < 0
      ) {
        this.shippingAmount = this.availableRefundShippings;
      }
      if (!this.shippingAmount) {
        this.shippingAmount = 0;
      }
      this.refundedAmout = this.grandTotal;
    },
    checkQYT(k, qty) {
      if (this.refundItems[k].refunded_qty > qty || this.refundItems[k].refunded_qty < 0) {
        this.refundItems[k].refunded_qty = qty;
      }
      if (!this.refundItems[k].refunded_qty) {
        this.refundItems[k].refunded_qty = 0;
      }
      let itemDataFind = this.data.itemsInfo.find(i => i.item_id === this.refundItems[k].item_id);
      let itemData = { ...itemDataFind };
      if (itemData && itemData.tax) {
        this.taxAmount = (itemData.tax * this.refundItems[k].refunded_qty).toFixed(2);
        this.taxAmountItems = (itemData.tax * this.refundItems[k].refunded_qty).toFixed(2);
      }
      this.refundedAmout = this.grandTotal;
    },
    async onRefundedItems() {
      let data = {};
      data.order = this.data.id;
      data.store = this.data.storeId;
      data.items = this.itemsRefunded;
      data.grandTotal = this.refundedAmout;
      data.subTotal = this.subTotal;
      data.discountTotal = 0;
      data.shippingTotal = this.shippingAmount;
      data.taxTotal = this.taxAmount;
      data.reason = this.reasonRefund;
      this.isLoadingAction = true;
      data.sendNotification = this.sendEmail;
      try {
        let res = await orderApi.createRefunded(data);
        console.log(data);
        this.$router.push({ name: 'website.orders.detail', params: { id: this.data.id } });
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
    async getData() {
      var vm = this;
      try {
        this.loading = true;
        this.idItem = this.$route.params.id;
        let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
        this.data = (await orderApi.getById(this.idItem)).data;
        console.log(this.data, 'data');
        let refundedData = await orderApi.getRefundedByOrderId(this.idItem);
        this.refundedData = refundedData.data.refunds;
        // refundedData là cái gì ?
        console.log(this.refundedData);
        this.refundedData.forEach(item => {
          item.items.forEach(element => {
            let findItem = this.refundedItems.find(i => i.item_id === element.item_id);
            if (findItem) {
              // console.log(findItem);
              let index = this.refundedItems.findIndex(i => i.item_id === element.item_id);
              if (index != -1) {
                let refunded_qty = element.refunded_qty + findItem.refunded_qty;
                vm.$set(this.refundedItems[index], 'refunded_qty', findItem.refunded_qty + element.refunded_qty);
              }
            } else {
              this.refundedItems.push(element);
            }
          });
        });
        this.data.fulfillments.forEach(item => {
          item.lineItems.forEach(element => {
            this.itemsFulfilled.push(element);
          });
        });
        this.data.itemsInfo.forEach(item => {
          let findItem = this.refundedItems.find(i => i.item_id === item.item_id);
          // console.log(findItem);
          let findItemfulfilled = vm.itemsFulfilled.find(i => i.id === item.item_id);
          if (findItem) {
            if (findItem.refunded_qty < item.qty) {
              let obj = {};
              obj.item_id = item.item_id;
              obj.refunded_qty = 0;
              obj.qty = parseInt(item.qty) - parseInt(findItem.refunded_qty);
              obj.price = item.price;
              obj.fulfilled = findItemfulfilled ? true : false;
              obj.product_id = item.product_id;
              obj.image = item.image;
              obj.title = item.title;
              obj._title = item._title;
              obj._variant_variantTitle = item._variant_variantTitle;
              obj.price = item.price;
              obj._sku = item._sku;
              obj._vendor = item._vendor;
              obj.attributes = item.attributes;
              obj.sku = item.sku;
              obj.personalizeText = item.personalizeText;
              // let
              this.refundItems.push(obj);
            }
          } else {
            let obj = {};
            obj.item_id = item.item_id;
            obj.qty = item.qty;
            obj.price = item.price;
            obj.refunded_qty = 0;
            obj.fulfilled = findItemfulfilled ? true : false;
            obj.product_id = item.product_id;
            obj.image = item.image;
            obj.title = item.title;
            obj._title = item._title;
            obj._variant_variantTitle = item._variant_variantTitle;
            obj.price = item.price;
            obj._sku = item._sku;
            obj._vendor = item._vendor;
            obj.attributes = item.attributes;
            obj.sku = item.sku;
            obj.personalizeText = item.personalizeText;
            this.refundItems.push(obj);
            // console.log(this.refundItems);
          }
        });
        console.log(this.refundItems, 'refundItem');
        this.data.name = `#${storeDomain.toUpperCase().split('.')[0]}-${this.data.orderId}`;
        this.timeline = this.data.transactions;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error, 'error');
      }
      this.loading = false;
    },
    calculateItemsPrice(item) {
      return parseFloat(parseInt(item?.refunded_qty || 0) * parseFloat(item?.price)).toFixed(2);
    },
    isPersonalizeProduct(item) {
      return Array.isArray(item?.personalizeText) && item?.personalizeText?.length > 0;
    },
    getPersonalizeTxt(item) {
      return item.personalizeText
        .map(item => {
          return item.text;
        })
        .join(',');
    },
    personalizeText(item) {
      let data = [];
      let findItem = this.data.paidInfo.items.find(i => i.item_id == item.item_id);
      data = findItem ? findItem.personalizeText : [];
      console.log(data);
      return data;
    },
  },
  watch: {
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
      },
      deep: true,
    },
  },
  computed: {
    subTotal() {
      let subTotal = this.refundItems.reduce((total, item) => {
        return parseFloat(total) * 1 + parseFloat(item.price * item.refunded_qty);
      }, 0);
      return parseFloat(subTotal).toFixed(2);
    },
    itemsRefunded() {
      let items = this.refundItems.filter(i => i.refunded_qty > 0);
      return items;
    },
    countItemsRefunded() {
      let count = 0;
      let items = this.refundItems.filter(i => i.refunded_qty > 0);
      count = items.length;
      return count;
    },
    availableRefundShippings() {
      let total = this.refundedData.reduce((total, item) => {
        return parseFloat(total) * 1 + parseFloat(item.shippingTotal);
      }, 0);
      return parseFloat(this.data.paidInfo.shipping_total - total).toFixed(2);
    },
    grandTotal() {
      let grandTotal = parseFloat(this.subTotal) + parseFloat(this.shippingAmount) + parseFloat(this.taxAmount);
      return grandTotal.toFixed(2);
    },
    totalRefunded() {
      let subTotal = this.refundedData.reduce((total, item) => {
        return parseFloat(total) + parseFloat(item.grandTotal);
      }, 0);
      return parseFloat(subTotal).toFixed(2);
    },
    totalShippingRefunded() {
      let shippingRefundedTotal = this.refundedData.reduce((total, item) => {
        return parseFloat(total) + parseFloat(item.shippingTotal);
      }, 0);
      return parseFloat(shippingRefundedTotal).toFixed(2);
    },
  },
  validations: {
    attributes: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
<style lang="scss">
.order-page--fulfill {
  img {
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .badge-info {
    display: flex;
    height: auto;
    min-width: 16px;
    padding: 0px 8px;
    font-size: 1em;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    background-color: #e4e5f0;
    border-radius: 10px;
  }
  .edit-label {
    font-weight: 700;
    color: $main-color;
    cursor: pointer;
  }
  .image-item {
    width: 50px;
    height: 50px;
    border: 0.2px solid #dddd;
    margin-right: 10px;
  }
  .title-info-customer {
    font-weight: 700;
    line-height: 1rem;
    text-transform: uppercase;
    font-size: 12px;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .table-fulfillment {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #000;
        th {
          font-size: 14px;
          color: #525c64;
          font-weight: 500;
          text-align: left;
          padding: 8px 10px;
          border-bottom: 1px solid #e4e5f0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }
}
</style>
